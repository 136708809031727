var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{staticClass:"bg-white"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-2 col-md-2 col-lg-1 col-xl-1 d-flex align-items-center justify-content-center"},[_c('img',{staticClass:"cursor-pointer",staticStyle:{"width":"3.5rem"},attrs:{"src":require("@/assets/imgs/quanta-play.gif"),"alt":""},on:{"click":function($event){return _vm.openVideoModal(
            'https://www.youtube.com/embed/UYJNhfIJ0Lw?si=hL1BgsJ5SDje8qzg',
            'Comece por aqui'
          )}}})]),_c('div',{staticClass:"col-12 col-sm-10 col-md-10 col-lg-11 col-xl-11 d-flex flex-column align-items-center align-items-md-start justify-content-center"},[_c('h5',{staticClass:"font-weight-bold display-md-4 text-green"},[_vm._v(" Comece por aqui ")]),_c('p',{staticClass:"text-sm-justify"},[_vm._v(" Explore nossa plataforma com os vídeos abaixo. Cada um deles detalha uma parte essencial de como ganhar por minuto, ajudando você a aproveitar ao máximo todas as funcionalidades disponíveis. Vamos juntos transformar suas compras e indicações em uma fonte de renda contínua! ")])])]),_c('b-row',{staticClass:"mt-3"},[_c('b-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('carousel',{attrs:{"perPage":3,"perPageCustom":[
          [0, 1],
          [768, 2],
          [1024, 3],
        ],"autoplay":true,"autoplayHoverPause":true,"autoplayTimeout":4000,"loop":true}},[_c('slide',[_c('div',{staticClass:"embed-container"},[_c('img',{staticClass:"cover-image cursor-pointer",attrs:{"src":require("@/assets/imgs/bem-vindo.jpg"),"alt":"Capa do Vídeo"},on:{"click":function($event){return _vm.openVideoModal(
                  'https://www.youtube.com/embed/oQdZeEagCfU?si=hL1BgsJ5SDje8qzg',
                  'Bem-vindo'
                )}}})])]),_c('slide',[_c('div',{staticClass:"embed-container"},[_c('img',{staticClass:"cover-image cursor-pointer",attrs:{"src":require("@/assets/imgs/como-funciona.jpg"),"alt":"Capa do Vídeo"},on:{"click":function($event){return _vm.openVideoModal(
                  'https://www.youtube.com/embed/R4QFuyN9yAo?si=hlhSEAgUTzXH17dE',
                  'Como Funciona'
                )}}})])]),_c('slide',[_c('div',{staticClass:"embed-container"},[_c('img',{staticClass:"cover-image cursor-pointer",attrs:{"src":require("@/assets/imgs/como-ganhar.jpg"),"alt":"Capa do Vídeo"},on:{"click":function($event){return _vm.openVideoModal(
                  'https://www.youtube.com/embed/CFDNYh_Iigs',
                  'Como Ganhar'
                )}}})])])],1)],1)],1),_c('b-modal',{attrs:{"id":"video-modal","title":_vm.videoTitle,"hide-footer":"","size":"lg","centered":""},on:{"hide":_vm.resetVideo}},[_c('b-embed',{staticClass:"embed-video",attrs:{"src":_vm.videoUrl,"type":"iframe","aspect":"16by9","allowfullscreen":""}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }