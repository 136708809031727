<template>
  <div>
    <div class="container-fluid pb-5">
      <div class="card mt-3 shadow rounded-0 border-0">
        <div
          class="card-header rounded-0"
          style="background-color: #388695; color: #fff"
        >
          <h5 class="text-uppercase mb-0">Acessos</h5>
        </div>
        <div class="card-body">
          <!-- Indicadores de Acesso -->
          <div class="row mb-4" style="">
            <div class="col-12 col-md-3 text-center text-white">
              <div class="indicator">
                <div class="value">
                  {{ dailyAccessCount }}
                </div>
                <p>Hoje</p>
              </div>
            </div>
            <div class="col-12 col-md-3 text-center text-white">
              <div class="indicator">
                <div class="value">{{ weeklyAccessCount }}</div>
                <p>Nos últimos 7 dias</p>
              </div>
            </div>
            <div class="col-12 col-md-3 text-center text-white">
              <div class="indicator">
                <div class="value">{{ monthlyAccessCount }}</div>
                <p>Este mês</p>
              </div>
            </div>
            <div class="col-12 col-md-3 text-center text-white">
              <div class="indicator">
                <div class="value">{{ lastMonthlyAccessCount }}</div>
                <p>Mês passado</p>
              </div>
            </div>
          </div>

          <!-- Filtros -->
          <div class="row mb-4">
            <div class="col-12">
              <b-form inline @submit.stop.prevent>
                <div class="row mb-4 align-items-center">
                  <div class="col-12 col-md-10 text-left">
                    <label for="inline-form-input-login-filter" class="mb-1"
                      >Filtrar por login</label
                    >
                    <b-form-input
                      id="inline-form-input-login-filter"
                      v-model="loginFilter"
                      placeholder="Digite o login e clique em buscar..."
                      class="mb-2"
                      style="width: 100%"
                    ></b-form-input>
                  </div>
                  <div class="col-12 col-md-2 d-flex align-items-center mt-3">
                    <b-button
                      variant="primary"
                      class="text-white btn-block"
                      @click="fetchAccesses()"
                      >Buscar</b-button
                    >
                  </div>
                </div>
              </b-form>
            </div>
          </div>

          <!-- Loading overlay -->
          <div v-if="isLoading" class="text-center my-4">
            <font-awesome-icon icon="spinner" spin size="2x" />
            <p class="mt-2">Carregando...</p>
          </div>

          <!-- Table (show only when not loading) -->
          <div v-else class="table-responsive">
            <table
              class="table table-hover table-striped table-bordered table-sm"
            >
              <thead>
                <tr>
                  <th class="text-center">Nome</th>
                  <th class="text-center">Login</th>
                  <th class="text-center">Email</th>
                  <th class="text-center"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="acesso in acessos" :key="acesso.login">
                  <td class="text-center">
                    {{ acesso.usuarioNome.toUpperCase() }}
                  </td>
                  <td class="text-center">{{ acesso.login.toLowerCase() }}</td>
                  <td class="text-center">{{ acesso.email.toLowerCase() }}</td>
                  <td class="text-center">
                    <b-button
                      size="sm"
                      variant="info"
                      style="color: #fff"
                      @click="viewDetails(acesso.idUsuario)"
                    >
                      Ver detalhes
                    </b-button>
                  </td>
                </tr>
                <tr v-if="acessos.length === 0">
                  <td colspan="4">
                    <span class="d-block text-center"
                      >Nenhum registro encontrado</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>

            <div
              class="d-flex flex-row align-items-center justify-content-between mt-3 px-2"
            >
              <pagination
                v-if="totalItems > 0"
                :current-page="currentPage"
                :total-items="totalItems"
                :items-per-page="itemsPerPage"
                @page-changed="fetchAccesses"
              />

              <div class="text-black-50">
                <p class="mb-0">
                  Exibindo {{ (currentPage - 1) * itemsPerPage + 1 }} a
                  <span v-if="currentPage * itemsPerPage > totalItems">{{
                    totalItems
                  }}</span>
                  <span v-else>{{ currentPage * itemsPerPage }}</span> de
                  {{ totalItems }} registros
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal v-if="isModalOpen" @close="closeModal">
      <template #header>
        <div
          class="d-flex align-items-center justify-content-center p-3 my-4"
          style="background-color: rgb(56, 134, 149)"
        >
          <h4
            class="w-100 text-center text-uppercase font-weight-bold mb-0"
            style="color: white"
          >
            Acessos do usuário
          </h4>
        </div>
      </template>
      <template #body>
        <div>
          <div
            v-for="(group, date) in groupedAccesses"
            :key="date"
            class="mb-4"
          >
            <h5
              class="text-center font-weight-bold"
              style="color: rgb(56, 134, 149)"
            >
              {{ date }}
            </h5>
            <table
              class="table table-sm table-striped table-hover table-bordered"
            >
              <thead>
                <tr>
                  <th class="text-center" style="color: rgb(56, 134, 149)">
                    Hora
                  </th>
                  <th class="text-center" style="color: rgb(56, 134, 149)">
                    Endereço IP
                  </th>
                  <th class="text-center" style="color: rgb(56, 134, 149)">
                    Dispositivo
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="group.length === 0">
                  <td colspan="3" class="text-center align-middle">
                    Nenhum registro encontrado
                  </td>
                </tr>
                <tr v-else v-for="access in group" :key="access">
                  <td class="text-center align-middle">
                    {{ formatTime(access.dataAcesso) }}
                  </td>
                  <td class="text-center align-middle">{{ access.ip }}</td>
                  <td class="text-left break-line align-middle">
                    {{ access.agente }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>
      <template #footer>
        <button class="btn btn-primary mt-4" @click="closeModal">Fechar</button>
      </template>
    </modal>
  </div>
</template>

<script>
import http from "@/_helper/api-services.js";
import Modal from "./Modal.vue";
import Pagination from "./Pagination.vue";

export default {
  components: { Modal, Pagination },
  data() {
    return {
      isLoading: false,
      currentPage: 1,
      itemsPerPage: 25,
      totalItems: 0,
      acessos: [],
      userAccesses: [],
      isModalOpen: false,
      loginFilter: "",
      dailyAccessCount: 0,
      weeklyAccessCount: 0,
      monthlyAccessCount: 0,
      lastMonthlyAccessCount: 0
    };
  },
  computed: {
    groupedAccesses() {
      return this.userAccesses.reduce((acc, access) => {
        const date = new Date(access.dataAcesso).toLocaleDateString();

        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(access);
        return acc;
      }, {});
    },
  },
  async created() {
    await this.fetchAccesses();
    await this.fetchAccessIndicators();
  },
  methods: {
    formatDate(data) {
      let dateTime = new Date(data);
      return (
        dateTime.toLocaleDateString() + " " + dateTime.toLocaleTimeString()
      );
    },
    formatTime(date) {
      return new Date(date).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    },
    async fetchAccessIndicators() {
      try {
        const response = await http.get("/v2/users/access-indicators");
        const indicators = response.data.data;
        this.dailyAccessCount = indicators.dailyAccessCount;
        this.weeklyAccessCount = indicators.weeklyAccessCount;
        this.monthlyAccessCount = indicators.monthlyAccessCount;
        this.lastMonthlyAccessCount = indicators.lastMonthAccessCount;
      } catch (error) {
        console.error("Erro ao buscar indicadores de acesso:", error);
      }
    },
    async fetchAccesses(page = 1) {
      try {
        const response = await http.get("/v2/users/access-list", {
          login: this.loginFilter,
          page,
          limit: this.itemsPerPage,
        });

        this.acessos = response.data.data.accesses;
        this.totalItems = response.data.data.totalItems; // E um campo 'total' para o número total de itens
        this.currentPage = page;
      } catch (error) {
        console.error("Erro ao buscar acessos:", error);
      }
    },
    async viewDetails(userId) {
      try {
        const response = await http.get(
          `/v2/users/accesses-by-user-id/${userId}`
        );

        this.userAccesses = response.data.data;
        this.isModalOpen = true;
      } catch (error) {
        console.error("Erro ao buscar detalhes do acesso:", error);
      }
    },
    closeModal() {
      this.isModalOpen = false;
      this.userAccesses = [];
    },
  },
};
</script>

<style scoped>
ul.list-group.list-group-striped li:nth-of-type(odd) {
  background: white;
}

ul.list-group.list-group-striped li:nth-of-type(even) {
  background: #e2e2e2;
}

ul.list-group.list-group-hover li:hover {
  background: #c2c2c2;
}

.indicator {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
  margin: 0px;
  margin-bottom: 15px;
  width: 100%;
  min-height: 164px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.indicator:hover {
  transform: translateY(-5px); /* Levanta o indicador ao passar o mouse */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Sombra ao passar o mouse */
}

.value {
  font-size: 2rem; /* Tamanho maior para o valor */
  font-weight: bold; /* Negrito para destaque */
  color: #007bff; /* Cor do texto */
}

p {
  margin-top: 10px; /* Espaçamento acima do texto */
  color: #6c757d; /* Cor mais suave para o texto */
}

.break-line {
  word-wrap: break-word;
  /* Para quebrar palavras longas */
  white-space: normal;
  /* Permite que o texto quebre em várias linhas */
}

.form-inline label {
  text-align: left !important;
  justify-content: flex-start;
}
</style>
