<template>
    <div class="modal-overlay">
        <div class="modal-content">
            <slot name="header"></slot>
            <slot name="body"></slot>
            <slot name="footer"></slot>
            <button class="close" @click="$emit('close')">X</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Modal'
};
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 640px;
    position: relative;
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 1.5rem;
}
</style>