<template>
  <div class="container-fluid pb-5">
    <div class="card mt-3 shadow rounded-0 border-0">
      <div
        class="card-header rounded-0"
        style="background-color: #388695; color: #fff"
      >
        <h5 class="text-uppercase mb-0">Parcelas aguardando pagamento</h5>
      </div>
      <div class="card-body">
        <div class="filters mb-3">
          <div class="row mb-3">
            <div class="col-12 col-md-3">
              <input
                v-model="login"
                type="text"
                class="form-control"
                placeholder="Filtrar por login"
              />
            </div>
            <div class="col-12 col-md-3">
              <input
                v-model="name"
                type="text"
                class="form-control"
                placeholder="Filtrar por nome"
              />
            </div>
            <div class="col-12 col-md-3">
              <input v-model="startDate" type="date" class="form-control" />
            </div>
            <div class="col-12 col-md-3">
              <input v-model="endDate" type="date" class="form-control" />
            </div>
          </div>

          <button
            class="btn btn-primary btn-block"
            @click="fetchReports"
            :disabled="isLoading"
          >
            {{ isLoading ? "Buscando..." : "Buscar" }}
          </button>
        </div>

        <!-- Loading overlay -->
        <div
          v-if="isLoading"
          class="d-flex justify-content-center align-items-center"
        >
          <div class="text-center text-black">
            <font-awesome-icon icon="spinner" spin size="1x" />
            <p class="mt-2">Carregando...</p>
          </div>
        </div>
        <div v-else>
          <div class="row align-items-center justify-content-center p-4">
            <div class="col-12 indicator">
              <div class="value text-danger">
                {{ formatValue(totalizersAwaiting.totalValue) }}
              </div>
              <p>Total em aberto no período</p>
            </div>
            <div class="col-12 indicator">
              <div class="value text-danger">
                {{ totalizersAwaiting.totalInstallmentsAwaitingPayment }}
              </div>
              <p>Total de parcelas em aberto no período</p>
            </div>

            <div class="col-12 indicator">
              <div class="value text-success">
                {{ formatValue(totalizersPaid.totalValue) }}
              </div>
              <p>Total pago no período</p>
            </div>
            <div class="col-12 indicator">
              <div class="value text-success">
                {{ totalizersPaid.totalPaidInstallments }}
              </div>
              <p>Total de parcelas pagas no período</p>
            </div>
          </div>

          <div class="table-responsive">
            <table
              class="table table-striped table-bordered table-hover table-sm"
            >
              <thead>
                <tr>
                  <th class="text-center align-middle">Nome</th>
                  <th class="text-center align-middle">Login</th>
                  <th class="text-center align-middle">Habilitação</th>
                  <th class="text-center align-middle">Valor</th>
                  <th class="text-center align-middle">Data da compra</th>
                  <th class="text-center align-middle">Número de parcelas</th>
                  <th class="text-center align-middle"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="reports.length === 0">
                  <td colspan="8" class="text-center">
                    Nenhum registro encontrado
                  </td>
                </tr>
                <tr v-for="item in reports" :key="item.IdCuponCashback">
                  <td class="text-left align-middle no-wrap">
                    {{ item.nome.toUpperCase() }}
                  </td>
                  <td class="text-left align-middle">
                    {{ item.login.toLowerCase() }}
                  </td>
                  <td class="text-left align-middle no-wrap">
                    {{ item.habilitacao && item.habilitacao.toUpperCase() }}
                  </td>

                  <td class="text-right align-middle">
                    {{ formatValue(item.valorPedido) }}
                  </td>
                  <td class="text-center align-middle">
                    {{ formatDate(item.dataPedido) }}
                  </td>
                  <td class="text-center align-middle">
                    {{ item.numeroParcelas }}
                  </td>

                  <td class="text-center align-middle">
                    <b-button
                      variant="info"
                      size="sm"
                      class="text-white"
                      @click="openModal(item)"
                      >Ver parcelas</b-button
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div
          class="d-flex flex-row align-items-center justify-content-between mt-3 px-2"
        >
          <pagination
            :current-page="pageNumber"
            :total-items="totalItems"
            :items-per-page="pageSize"
            @page-changed="onPageChanged"
          />

          <div class="text-black-50">
            <p class="mb-0">
              Exibindo {{ (pageNumber - 1) * pageSize + 1 }} a
              <span v-if="pageNumber * pageSize > totalItems">{{
                totalItems
              }}</span>
              <span v-else>{{ pageNumber * pageSize }}</span> de
              {{ totalItems }} registros
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <b-modal
      id="modal-installments"
      v-model="isModalOpen"
      :title="
        selectedItem
          ? `Parcelas de ${selectedItem.nome.toUpperCase()}`
          : `Parcelas`
      "
      @hide="closeModal"
      centered
      scrollable
      size="xl"
    >
      <div>
        <p class="text-uppercase font-weight-bold mb-4 text-center">
          Compra #{{ installments.length > 0 && installments[0].idPedido }}
        </p>

        <div class="row mb-3">
          <div class="col-12 col-md-5 offset-md-1 border mr-1 bg-light">
            <div
              class="value text-success d-flex align-items-center justify-content-center bordered"
            >
              {{ formatValue(totalPaid) }}
            </div>
            <p class="text-center">Total pago</p>
          </div>
          <div class="col-12 col-md-5 offset-md-1 border ml-1 bg-light">
            <div
              class="value text-danger d-flex align-items-center justify-content-center bordered"
            >
              {{ formatValue(totalNotPaid) }}
            </div>
            <p class="text-center">Total em aberto</p>
          </div>
        </div>

        <div class="table-responsive">
          <table
            class="table table-striped table-hover table-bordered table-sm"
          >
            <thead>
              <tr>
                <th class="text-center">#</th>
                <th class="text-center">Valor</th>
                <th class="text-center">Data de vencimento</th>
                <th class="text-center">Data de pagamento</th>
                <th class="text-center">Situação</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="installment in installments"
                :key="installment.numeroParcela"
              >
                <td class="text-center align-middle">
                  {{ installment.numeroParcela }}
                </td>
                <td class="text-right align-middle">
                  {{ formatValue(installment.valor) }}
                </td>
                <td class="text-center align-middle">
                  {{ formatDate(installment.dataValidade) }}
                </td>
                <td class="text-center align-middle">
                  {{
                    installment.dataPagamento
                      ? formatDate(installment.dataPagamento)
                      : "---"
                  }}
                </td>
                <td class="text-center align-middle">
                  <span
                    v-if="installment.pago"
                    class="text-uppercase text-white bg-success d-block"
                    ><small>Pago</small></span
                  >
                  <span
                    v-if="!installment.pago"
                    class="text-uppercase text-white bg-danger d-block"
                    ><small>Em aberto</small></span
                  >
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td class="text-right align-middle" colspan="1">
                  <strong>Total:</strong>
                </td>
                <td class="text-right align-middle">
                  <strong>{{ formatValue(totalValue) }}</strong>
                </td>
                <td colspan="3"></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <b-button slot="modal-footer" @click="closeModal">Fechar</b-button>
    </b-modal>
    <!-- Modal -->
  </div>
</template>

<script>
import http from "@/_helper/api-services.js";
import Pagination from "./Pagination.vue"; // Componente de paginação

export default {
  components: { Pagination },
  data() {
    const currentYear = new Date().getFullYear();
    return {
      login: "",
      name: "",
      startDate: new Date(currentYear - 10, 0, 1).toISOString().split("T")[0], // 01/01 do ano atual - 10 anos
      endDate: new Date(currentYear + 1, 11, 31).toISOString().split("T")[0], // Último dia do ano corrente + 1
      reports: [],
      pageNumber: 1,
      pageSize: 50,
      totalItems: 0,
      totalizersAwaiting: {},
      totalizersPaid: {},
      isLoading: false,
      isModalOpen: false,
      installments: [],
      totalPaid: 0,
      totalNotPaid: 0,
      selectedItem: undefined,
    };
  },
  computed: {
    totalValue() {
      return this.installments.reduce((sum, installment) => {
        return sum + (installment.valor || 0);
      }, 0);
    },
  },
  methods: {
    async fetchReports() {
      this.isLoading = true;
      try {
        const response = await http.get(
          "/v2/admin/reports/get-installments-awaiting-payment",
          {
            login: this.login,
            name: this.name,
            startDate: this.startDate,
            endDate: this.endDate,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
          }
        );

        this.reports = response.data.data.items;
        this.totalItems = response.data.data.totalItems;
        this.totalizersAwaiting = response.data.data.totalizersAwaiting;
        this.totalizersPaid = response.data.data.totalizersPaid;
      } catch (error) {
        console.error("Erro ao buscar relatórios:", error);
      } finally {
        this.isLoading = false; // Desativa o estado de carregamento
      }
    },
    onPageChanged(newPage) {
      this.isLoading = true;
      this.pageNumber = newPage;
      this.fetchReports();
      this.isLoading = false;
    },
    formatValue(value) {
      if (!value) value = 0;

      try {
        const numericalValue = parseFloat(value); // Tenta converter para número
        if (isNaN(numericalValue)) {
          throw new Error("Valor inválido: " + value); // Lança erro se não for um número
        }
        return new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(numericalValue);
      } catch (error) {
        console.error("Erro ao formatar valor:", error);
        return value; // Retorna o valor original em caso de erro
      }
    },
    formatPercentage(value) {
      try {
        const numericalValue = parseFloat(value); // Converte o valor para número
        if (isNaN(numericalValue)) {
          throw new Error("Valor inválido: " + value); // Lança erro se não for um número
        }
        return new Intl.NumberFormat("pt-BR", {
          style: "percent",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(numericalValue);
      } catch (error) {
        console.error("Erro ao formatar percentual:", error);
        return value; // Retorna o valor original em caso de erro
      }
    },
    formatDate(date) {
      if (!date) return null;

      try {
        return new Intl.DateTimeFormat("pt-BR").format(new Date(date));
      } catch (error) {
        console.error("Erro ao formatar data:", error);
        return date; // Retorna a data original em caso de erro
      }
    },
    openModal(item) {
      this.isModalOpen = true;
      this.selectedItem = item;
      this.fetchInstallments(item.idPedido); // Chama o método para buscar os registros
    },
    closeModal() {
      this.isModalOpen = false;
      this.installments = [];
      this.totalPaid = 0;
      this.totalNotPaid = 0;
      this.selectedItem = undefined;
    },
    async fetchInstallments(orderId) {
      this.isLoading = true;
      try {
        const response = await http.get(
          `/v2/admin/reports/get-installments-by-order/${orderId}`
        );
        this.installments = response.data.data;

        if (this.installments.length > 0) {
          this.installments.forEach((item) => {
            if (item.pago) {
              this.totalPaid += item.valor;
            } else {
              this.totalNotPaid += item.valor;
            }
          });
        }
      } catch (error) {
        console.error("Erro ao buscar parcelas:", error);
      } finally {
        this.isLoading = false; // Desativa o estado de carregamento
      }
    },
  },
  mounted() {
    this.fetchReports();
  },
};
</script>

<style scoped>
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Preto com 50% de opacidade */
  z-index: 1000; /* Garantir que fique acima de outros elementos */
}

@media (min-width: 960px) {
  .indicator {
    max-width: 23%;
  }
}

.indicator {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
  margin: 0 10px;
  margin-bottom: 15px;
  width: 100%;
  min-height: 152px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.indicator:hover {
  transform: translateY(-5px); /* Levanta o indicador ao passar o mouse */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Sombra ao passar o mouse */
}

.value {
  font-size: 1.5rem; /* Tamanho maior para o valor */
  font-weight: bold; /* Negrito para destaque */
  color: #007bff; /* Cor do texto */
}

p {
  margin-top: 10px; /* Espaçamento acima do texto */
  color: #6c757d; /* Cor mais suave para o texto */
}

.no-wrap {
  white-space: normal;
}
</style>
