<template>
    <b-container class="bg-white">
        <InfoVideo title="Quanta Amizade" video-url="https://www.youtube.com/embed/KGLrKuIKUoM?rel=0" />

        <div class="row">
            <div class="col-12 col-sm-2 col-md-2 col-lg-1 col-xl-1 d-flex align-items-center justify-content-center">
                <img src="@/assets/imgs/comece-por-aqui.png" alt="" class="ranking-icon" />
            </div>
            <div
                class="col-12 col-sm-10 col-md-10 col-lg-11 col-xl-11 d-flex flex-column align-items-center align-items-md-start justify-content-center">
                <h5 class="font-weight-bold display-md-4 text-green">Quanta Amizade</h5>
                <p class="text-sm-justify hidden-mobile">
                    A cada amigo indicado que alcançar os primeiros R$ 50 em cashback, você ganha R$ 25 e ele ganha mais
                    R$ 25! Aproveite a chance de juntar a galera e ganhar juntos um bom dinheiro por minuto. Quanto mais
                    amigos você trouxer, mais você ganha!
                </p>
                <p class="text-sm-justify hidden-desktop">
                    A cada amigo indicado que alcançar os primeiros R$ 50 em cashback, você ganha R$ 25 e ele ganha mais
                    R$ 25! Aproveite a chance de juntar a galera e ganhar juntos um bom dinheiro por minuto. Quanto mais
                    amigos você trouxer, mais você ganha!
                </p>
            </div>
        </div>

        <div class="row" v-if="elegivel.Total < elegivel.Valor">
            <div class="col-12">
                <div>
                    <b-alert fade show variant="warning" class="mr-2 text-center">
                        😲 Você ainda não acumulou R$ 50 de cashback para participar da
                        campanha? Não perca tempo e convide seus amigos!
                    </b-alert>
                </div>
            </div>
        </div>

        <b-row v-if="quantaAmizade.length <= 0">
            <b-col>
                <!-- <h6>Bem-vindo à nossa comunidade! 🎉</h6>
                        <p>Agora que você faz parte do nosso sistema, está na hora de aproveitar ao máximo todas as vantagens que temos a oferecer. Sabia que você pode ganhar ainda mais cashback simplesmente convidando seus amigos para se juntarem à nossa rede?</p>
                        <p><strong>💰 Como funciona?</strong><br/>A cada novo usuário que você traz, você ganha uma porcentagem do cashback deles. Quanto mais pessoas na sua rede, maior o seu potencial de ganho!</p>
                        <p><strong>🎁 Vantagens:</strong></p>
                        <ul>
                            <li><strong>Mais Cashback:</strong> Aumente seus ganhos a cada novo convite aceito.</li>
                            <li><strong>Facilidade:</strong> Convide seus amigos com apenas um clique!</li>
                            <li><strong>Recompensas Especiais:</strong> Usuários que trouxerem mais pessoas ganham bônus exclusivos.</li>
                        </ul>
                        <p><strong>Não perca tempo!</strong> Compartilhe seu link de convite agora e comece a ver seus ganhos crescerem. Quanto mais você compartilhar, mais você ganha. É simples, fácil e recompensador!</p>
                        <p>Estamos felizes por ter você com a gente e mal podemos esperar para ver sua rede crescer! 🚀</p> -->

                <a href="https://bigcash.blob.core.windows.net/documentos/CAMPANHA%20QUANTA%20AMIZADE.pdf"
                    target="_blank">
                    <img src="@/assets/imgs/Banner-Quanta-Bank-01.jpg" alt="" class="rounded d-none d-md-block"
                        style="width: 100%" />
                    <img src="@/assets/imgs/BannerMob-Quanta-Bank-01.jpg" alt="" class="rounded d-md-none"
                        style="width: 100%" />
                </a>
            </b-col>
        </b-row>
        <b-row v-show="quantaAmizade.length > 0" v-for="(amizade, index) in quantaAmizade" :key="index" class="mt-4">
            <b-col cols="12" md="2"
                class="d-flex flex-column align-items-center justify-content-center avatar-container">
                <span class="b-avatar badge-light rounded-circle" style="width: 90px; height: 90px">
                    <span class="b-avatar-img">
                        <img alt="avatar" :src="amizade.URLIMG" @error="onImageError" />
                    </span>
                </span>
            </b-col>
            <b-col cols="12" md="10">
                <h5 class="text-uppercase text-green amizade-nome">
                    {{ amizade.Nome }}
                    <span class="" v-if="amizade.ValorCashbackAcumulado >= 50.0" title="Você conseguiu">🎉🤑</span>
                </h5>
                <b-progress :value="amizade.ValorCashbackAcumulado" :max="max" class="mb-3"></b-progress>
                <span class="text-muted d-block text-right valor-acumulado">
                    Valor acumulado até agora:
                    {{ amizade.ValorCashbackAcumulado | formatCurrency }}
                </span>
                <span class="text-muted d-block text-right periodo">
                    Período de {{ amizade.DataCadastro | formatDate }} até
                    {{ amizade.DataLimite | formatDate }}
                </span>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import http from "@/_helper/api-services.js";
import InfoVideo from "./InfoVideo.vue";

export default {
    async beforeMount() {
        this.$loading(true);
        await this.buscarQuantaAmizade();
        await this.verificarEligibilidade();
        this.$loading(false);
    },
    components: {
        InfoVideo
    },
    data() {
        return {
            login: "",
            value: 0,
            max: 50.0,
            quantaAmizade: [],
            elegivel: {},
        };
    },
    methods: {
        async buscarQuantaAmizade() {
            this.$loading(true);
            await http.get("/v2/dashboard/get-friendship-info").then(
                (success) => {
                    this.quantaAmizade = success.data.data;
                    this.$loading(false);
                },
                () => {
                    this.$snotify.error("Erro ao buscar o QuantaAmizade");
                    this.$loading(false);
                }
            );
            this.$loading(false);
        },
        async verificarEligibilidade() {
            this.$loading(true);
            await http.get("/v2/dashboard/get-check-user-eligibility").then(
                (success) => {
                    this.elegivel = success.data.data;
                    this.$loading(false);
                },
                () => {
                    this.$snotify.error("Erro ao buscar o QuantaAmizade");
                    this.$loading(false);
                }
            );
            this.$loading(false);
        },
        onImageError(event) {
            event.target.src = this.defaultImage;
        },
    },
};
</script>

<style scoped>
@media (max-width: 412px) {
    ol {
        padding-left: 20px;
    }
}

.amizade-nome {
    font-size: 1rem;
    font-weight: bold;
}
</style>

<style>
.progress-bar {
    background-color: #1e5d68 !important;
}

@media (max-width: 412px) {
    ol {
        padding-left: 20px;
    }
}

.periodo,
.valor-acumulado {
    font-size: 12px;
}

.text-green {
    color: #1e5d68;
}

/* Esconde o texto em dispositivos móveis */
.hidden-mobile {
    display: none;
}

/* Esconde o texto em dispositivos grandes */
.hidden-desktop {
    display: none;
}

/* Exibe o texto em dispositivos móveis */
@media (max-width: 767px) {
    .hidden-mobile {
        display: none;
    }

    .hidden-desktop {
        display: block;
    }
}

/* Exibe o texto em dispositivos grandes */
@media (min-width: 768px) {
    .hidden-mobile {
        display: block;
    }

    .hidden-desktop {
        display: none;
    }
}

.avatar-container {
    background: #ecf2f7;
    border-radius: 8px;
    box-shadow: 0 1px 2px #010f1c1a;
    transition: all 0.3s ease-out 0s;
}

.b-avatar .b-avatar-img img {
    width: auto !important;
    height: 100%;
    max-height: auto;
    border-radius: inherit;
    -o-object-fit: cover;
    object-fit: contain;
}

.title-color {
    color: #98c73a !important;
}
</style>
