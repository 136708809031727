<template>
  <div class="container-fluid pb-5">
    <div class="card mt-3 shadow rounded-0 border-0">
      <div
        class="card-header rounded-0"
        style="background-color: #388695; color: #fff"
      >
        <h5 class="text-uppercase mb-0">
          Relatórios de vendas aguardando aprovação
        </h5>
      </div>
      <div class="card-body">
        <div class="filters mb-3">
          <div class="row mb-3">
            <div class="col-12 col-md-4">
              <input
                v-model="seller"
                type="text"
                class="form-control"
                placeholder="Filtrar por nome do estabelecimento"
              />
            </div>
            <div class="col-12 col-md-4">
              <input v-model="startDate" type="date" class="form-control" />
            </div>
            <div class="col-12 col-md-4">
              <input v-model="endDate" type="date" class="form-control" />
            </div>
          </div>

          <button
            class="btn btn-primary btn-block"
            @click="fetchReports"
            :disabled="isLoading"
          >
            {{ isLoading ? "Buscando..." : "Buscar" }}
          </button>
        </div>

        <!-- Loading overlay -->
        <div
          v-if="isLoading"
          class="d-flex justify-content-center align-items-center"
        >
          <div class="text-center text-black">
            <font-awesome-icon icon="spinner" spin size="1x" />
            <p class="mt-2">Carregando...</p>
          </div>
        </div>
        <div v-else class="table-responsive">
          <div class="row align-items-center justify-content-center p-4">
            <div class="col-12 indicator">
              <div class="value">
                {{ formatValue(totalizers.totalEmVendas) }}
              </div>
              <p>Total em vendas no período</p>
            </div>
            <div class="col-12 indicator">
              <div class="value">
                {{ formatValue(totalizers.totalEmCashback) }}
              </div>
              <p>Total em cashback no período</p>
            </div>
            <div class="col-12 indicator">
              <div class="value">
                {{ formatPercentage(totalizers.mediaCashback) }}
              </div>
              <p>Média do cashback no período</p>
            </div>
          </div>

          <table
            class="table table-striped table-bordered table-hover table-sm"
          >
            <thead>
              <tr>
                <th class="text-center align-middle">Consumidor</th>
                <th class="text-center align-middle">Vendedor</th>
                <th class="text-center align-middle">Credenciador</th>
                <th class="text-center align-middle">Valor da compra</th>
                <th class="text-center align-middle">Percentual cashback</th>
                <th class="text-center align-middle">Cashback</th>
                <th class="text-center align-middle">Data da compra</th>
                <th class="text-center align-middle">Descrição</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="reports.length === 0">
                <td colspan="8" class="text-center">
                  Nenhum registro encontrado
                </td>
              </tr>
              <tr v-for="item in reports" :key="item.IdCuponCashback">
                <td class="text-center align-middle">
                  {{ item.consumidor.toLowerCase() }}
                </td>
                <td class="text-center align-middle">
                  {{ item.vendedor.toUpperCase() }}
                </td>
                <td class="text-center align-middle">
                  {{ item.credenciador.toLowerCase() }}
                </td>
                <td class="text-right align-middle">
                  {{ formatValue(item.valor) }}
                </td>
                <td class="text-right align-middle">
                  {{ formatPercentage(item.percentualCashback) }}
                </td>
                <td class="text-right align-middle">
                  {{ formatValue(item.valor * item.percentualCashback) }}
                </td>
                <td class="text-center align-middle">
                  {{ formatDate(item.dataCompra) }}
                </td>
                <td>{{ item.descricao }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          class="d-flex flex-row align-items-center justify-content-between mt-3 px-2"
        >
          <pagination
            :current-page="pageNumber"
            :total-items="totalItems"
            :items-per-page="pageSize"
            @page-changed="onPageChanged"
          />

          <div class="text-black-50">
            <p class="mb-0">
              Exibindo {{ (pageNumber - 1) * pageSize + 1 }} a
              <span v-if="pageNumber * pageSize > totalItems">{{
                totalItems
              }}</span>
              <span v-else>{{ pageNumber * pageSize }}</span> de
              {{ totalItems }} registros
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "@/_helper/api-services.js";
import Pagination from "./Pagination.vue"; // Componente de paginação

export default {
  components: { Pagination },
  data() {
    const currentYear = new Date().getFullYear();
    return {
      seller: "",
      startDate: new Date(currentYear - 10, 0, 1).toISOString().split("T")[0], // 01/01 do ano atual - 10 anos
      endDate: new Date(currentYear + 1, 11, 31).toISOString().split("T")[0], // Último dia do ano corrente + 1
      reports: [],
      pageNumber: 1,
      pageSize: 50,
      totalItems: 0,
      totalizers: {},
      isLoading: false,
    };
  },
  methods: {
    async fetchReports() {
      this.isLoading = true;
      try {
        const response = await http.get(
          "/v2/admin/reports/get-sales-waiting-for-approval",
          {
            seller: this.seller,
            startDate: this.startDate,
            endDate: this.endDate,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
          }
        );
        this.reports = response.data.data.items;
        this.totalItems = response.data.data.totalItems;
        this.totalizers = response.data.data.totalizers;
      } catch (error) {
        console.error("Erro ao buscar relatórios:", error);
      } finally {
        this.isLoading = false; // Desativa o estado de carregamento
      }
    },
    onPageChanged(newPage) {
      this.isLoading = true;
      this.pageNumber = newPage;
      this.fetchReports();
      this.isLoading = false;
    },
    formatValue(value) {
      try {
        const numericalValue = parseFloat(value); // Tenta converter para número
        if (isNaN(numericalValue)) {
          throw new Error("Valor inválido"); // Lança erro se não for um número
        }
        return new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(numericalValue);
      } catch (error) {
        console.error("Erro ao formatar valor:", error);
        return value; // Retorna o valor original em caso de erro
      }
    },
    formatPercentage(value) {
      try {
        const numericalValue = parseFloat(value); // Converte o valor para número
        if (isNaN(numericalValue)) {
          throw new Error("Valor inválido"); // Lança erro se não for um número
        }
        return new Intl.NumberFormat("pt-BR", {
          style: "percent",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(numericalValue);
      } catch (error) {
        console.error("Erro ao formatar percentual:", error);
        return value; // Retorna o valor original em caso de erro
      }
    },
    formatDate(date) {
      try {
        return new Intl.DateTimeFormat("pt-BR").format(new Date(date));
      } catch (error) {
        console.error("Erro ao formatar data:", error);
        return date; // Retorna a data original em caso de erro
      }
    },
  },
  mounted() {
    this.fetchReports();
  },
};
</script>

<style scoped>
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Preto com 50% de opacidade */
  z-index: 1000; /* Garantir que fique acima de outros elementos */
}

@media (min-width: 960px) {
    .indicator {
        max-width: 30%;       
    }
}

.indicator {
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
    margin: 0 10px;
    margin-bottom: 15px;
    width: 100%;
}

.indicator:hover {
    transform: translateY(-5px); /* Levanta o indicador ao passar o mouse */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Sombra ao passar o mouse */
}

.value {
    font-size: 2rem; /* Tamanho maior para o valor */
    font-weight: bold; /* Negrito para destaque */
    color: #007bff; /* Cor do texto */
}

p {
    margin-top: 10px; /* Espaçamento acima do texto */
    color: #6c757d; /* Cor mais suave para o texto */
}
</style>
